<!-- 
    @component
    
    IMAGE
    
    This component ensures images are loaded in the best possible manner, depending on the environment.

    In the webapp, it uses the TwicImg component from @twicpics/components/sveltekit. This component
    provides automatic optimizations, lazy loading, etc. (in conjunction with TwicPic's inline script).

    In the extension, it uses a simple <img /> tag and loads the image from the TwicPics CDN. This is done
    because inline scripts are not permitted under Manifest v3.
    
    @prop src - The path to the image.
	@prop anchor - The anchor of the image.
	@prop state - The state of the image.
    @prop title - The title of the image.
    @prop ratio - The ratio of the image.
    @prop mode - The mode of the image.
    @prop placeholder - The placeholder of the image.
    @prop rounded - The border radius of the image.
	@prop refit - Whether to refit the image. If a string is provided, those values denote the padding to be applied from border to the edge of the image. 
    @prop alt - The alt text of the image.
    @prop classes - The classes to apply to the image.
    @prop extensionImgOverrides - Overrides for the extension image.

-->
<script context="module" lang="ts">
	export type ExtensionImageOverrides = {
		height?: string;
		imgClasses?: string;
		width?: string;
		wrapperClasses?: string;
	};

	const DEFAULT_EXTENSION_IMAGE_OVERRIDES: ExtensionImageOverrides = {
		height: undefined,
		imgClasses: '',
		width: undefined,
		wrapperClasses: ''
	};
</script>

<script lang="ts">
	import type { State } from '@twicpics/components/sveltekit';

	import { PUBLIC_TWICPICS_PATH_PREFIX, PUBLIC_TWICPICS_URL } from '$env/static/public';
	import { TwicImg } from '@twicpics/components/sveltekit';

	export let src: string;
	export let anchor: string | undefined = undefined;
	export let state: State = 'new';
	export let title: string = '';
	export let ratio: string | undefined = undefined;
	export let mode: 'contain' | 'cover' | undefined = undefined;
	export let placeholder: 'maincolor' | 'meancolor' | undefined = undefined;
	export let rounded: string = '';
	export let refit: boolean | string | undefined = undefined;
	export let alt: string = '';
	export let classes: string = '';
	export let extensionImgOverrides = DEFAULT_EXTENSION_IMAGE_OVERRIDES;

	const baseTwicUrl = `${PUBLIC_TWICPICS_URL}${PUBLIC_TWICPICS_PATH_PREFIX}`;

	$: overrides = {
		...DEFAULT_EXTENSION_IMAGE_OVERRIDES,
		...extensionImgOverrides
	};
</script>

<!-- Use a static env var (rather than $isExtensionStore), so that the code is split at build time. -->
{#if import.meta.env.VITE_IS_EXTENSION === 'true'}
	<div class="{rounded} {overrides.wrapperClasses}">
		<img
			{alt}
			src="{baseTwicUrl}{src}"
			{title}
			class="{rounded} {overrides.imgClasses}"
			on:error={() => {
				state = 'error';
			}}
		/>
	</div>
{:else}
	<TwicImg
		{alt}
		{anchor}
		{mode}
		{placeholder}
		{ratio}
		{refit}
		{src}
		{title}
		class="{rounded} {classes}"
		bind:state
	/>
{/if}
